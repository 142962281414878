import React from 'react';

import {
  Feedback,
  QuizSubmission,
} from '@wix/ambassador-challenges-v1-participant/types';

export interface IResolveStepContext {
  isResolveStepRequestInProgress: boolean;
  resolveStep(
    stepId: string,
    feedback?: Feedback,
    quizSubmission?: QuizSubmission,
    isUpdate?: boolean,
  ): Promise<void>;
  resolveStepError: string;
}

export const ResolveStepContext = React.createContext<IResolveStepContext>({
  isResolveStepRequestInProgress: false,
  resolveStep: (() => {}) as any,
  resolveStepError: null,
});

export const useResolveStep = () => React.useContext(ResolveStepContext);

export const ResolveStepConsumer = ResolveStepContext.Consumer;
