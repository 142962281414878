import React from 'react';
import { UserContext, IUserContext } from './UserContext';

export interface IUserProviderProps extends IUserContext {}

export const UserProvider: React.FC<IUserProviderProps> = (props) => {
  return (
    <UserContext.Provider
      value={{
        incrementParticipantsCompletedStepSummary:
          props.incrementParticipantsCompletedStepSummary,
        user: props.user,
        updateParticipant: props.updateParticipant,
        userType: props.userType,
        isParticipantInSuspendedState: props.isParticipantInSuspendedState,
        participant: props.participant,
        promptLogin: props.promptLogin,
        join: props.join,
        userTypeHandlers: props.userTypeHandlers,
        cancelJoinRequest: props.cancelJoinRequest,
        leaveTheChallenge: props.leaveTheChallenge,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
