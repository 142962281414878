import React from 'react';

export interface IFullscreenContext {
  toggleFullscreen(element: HTMLElement): Promise<void>;
  isFullscreen: boolean;
}

export const FullscreenContext = React.createContext<IFullscreenContext>({
  toggleFullscreen: null,
  isFullscreen: null,
});

export const useFullscreen = () => React.useContext(FullscreenContext);
