import React from 'react';

import { IQuizContext, QuizContext, quizDefaultValue } from './QuizContext';

export const QuizProvider: React.FC<IQuizContext> = (props) => {
  return (
    <QuizContext.Provider
      value={{
        controllerIsReady:
          props.controllerIsReady || quizDefaultValue.controllerIsReady,
        initController: props.initController || quizDefaultValue.initController,
        sendSubmission: props.sendSubmission || quizDefaultValue.sendSubmission,
        lastSubmissionId:
          props.lastSubmissionId || quizDefaultValue.lastSubmissionId,
        getSubmission: props.getSubmission || quizDefaultValue.getSubmission,
        submission: props.submission || quizDefaultValue.submission,
      }}
    >
      {props.children}
    </QuizContext.Provider>
  );
};
