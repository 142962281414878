import React from 'react';
import { ToastType } from './interfaces';

export interface IToastContext {
  showToast(
    content: React.ReactNode,
    options?: {
      type: ToastType;
    },
  ): void;
}

export const ToastContext = React.createContext<IToastContext>({
  showToast: null,
});

export const ToastContextConsumer = ToastContext.Consumer;
