export interface IToastProviderProps {
  errorMessageKey: string;

  clearError(): void;
}

export enum ToastType {
  success = 'success',
  error = 'error',
}
