import React from 'react';
import { ChallengeDataConsumer } from './ChallengeDataContext';

export function withChallengeData<Props>(Component: React.FC<any>) {
  return (props) => {
    return (
      <ChallengeDataConsumer>
        {(value) => {
          return <Component {...(props as Props)} {...value} />;
        }}
      </ChallengeDataConsumer>
    );
  };
}
