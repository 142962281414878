import React from 'react';
import { ChallengesListDataContext } from './ChallengesListContext';
import { IChallengesListDataProps } from './challengesListDataProviderPropsMap';

export const ChallengesListDataProvider: React.FC<IChallengesListDataProps> = (
  props,
) => {
  return (
    <ChallengesListDataContext.Provider
      value={{
        challengesListData: props.challengesListData,
        programPageURI: props.programPageURI,
      }}
    >
      {props.children}
    </ChallengesListDataContext.Provider>
  );
};
