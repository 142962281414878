import React from 'react';
import { st, classes } from './PaymentCheckout.st.css';
import { Divider } from 'wix-ui-tpa';

export interface PaymentCheckoutInfoProps {
  title: string;
  price: string;
  duration: string;
  showOneAppInfo(): void;
  t(key: string): string;
}

export const PaymentCheckout: React.FC<PaymentCheckoutInfoProps> = (props) => {
  return (
    <section className={st(classes.root, {})}>
      <h1 className={classes.title}>{props.title}</h1>
      <time className={classes.duration}>{props.duration}</time>
      <Divider className={classes.separator} />
      <dl className={classes.priceContainer}>
        <td className={classes.priceTitle}>
          {props.t('payment.page.total-price')}
        </td>
        <dd className={classes.priceValue}>{props.price}</dd>
      </dl>
    </section>
  );
};
