import React from 'react';
import { ChallengeDataContext } from './ChallengeDataContext';
import { IChallengeDataProps } from './helpers';

export const ChallengeDataProvider: React.FC<IChallengeDataProps> = (props) => {
  return (
    <ChallengeDataContext.Provider
      value={{
        isDescriptionShown: props.isDescriptionShown,
        challengeData: props.challengeData,
        challengeSections: props.challengeSections,
        challengeSteps: props.challengeSteps,
        requestChallengeSections: props.requestChallengeSections,
        requestChallengeSteps: props.requestChallengeSteps,
        isMyWalletInstalled: props.isMyWalletInstalled,
        isProfileInstalled: props.isProfileInstalled,
      }}
    >
      {props.children}
    </ChallengeDataContext.Provider>
  );
};
