import React from 'react';
import { GeneralDataContextConsumer } from './GeneralDataContext';

export function withGeneralData<T = any>(Component: any): React.FC<T> {
  return (props) => {
    return (
      <GeneralDataContextConsumer>
        {(value) => {
          return <Component {...(props as T)} {...value} />;
        }}
      </GeneralDataContextConsumer>
    );
  };
}
