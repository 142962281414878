import React from 'react';

import {
  ParticipantSection,
  ListParticipantStepsResponse,
} from '@wix/ambassador-challenges-v1-participant/types';

export interface IParticipantSectionsContext {
  selectedSection?: ParticipantSection;
  isListParticipantSectionsRequestInProgress: boolean;
  listParticipantSections: ParticipantSection[];
  participantSteps: ListParticipantStepsResponse;
  updateParticipantSections(): void;
}

export const participantSectionsContextDefaultValue = {
  selectedSection: undefined,
  isListParticipantSectionsRequestInProgress: false,
  listParticipantSections: null,
  participantSteps: null,
  updateParticipantSections: null,
};

export const ParticipantSectionsContext =
  React.createContext<IParticipantSectionsContext>(
    participantSectionsContextDefaultValue,
  );

export const useSections = () => React.useContext(ParticipantSectionsContext);

export const ParticipantSectionsConsumer = ParticipantSectionsContext.Consumer;
