import React from 'react';

import { contextFactory } from '../../services/contextFactory';
import { IFileUploadContext, IFileUploadProviderProps } from './interfaces';

export const { context, ContextProvider, withConsumer } = contextFactory<
  IFileUploadContext,
  IFileUploadProviderProps
>({
  defaultValues: {
    uploadFile: null,
    clearUploadedFile: null,
    uploadedFile: null,
    itemsLoading: 0,
    setItemsLoading: null,
  },
  displayName: 'FileUploadProvider',
  propsToContext(props): IFileUploadContext {
    return {
      ...props,
    };
  },
});

export const FileUploadContext = context;
export const FileUploadProvider = ContextProvider;
export const useFileUpload = () => React.useContext(FileUploadContext);
