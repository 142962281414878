import React from 'react';

import { ILocalStorageContext } from './interfaces';

export const LocalStorageContext = React.createContext<ILocalStorageContext>({
  requestItem: null,
  setItem: null,
  storage: {},
});

export const useLocalStorage = () => React.useContext(LocalStorageContext);
