import { contextFactory } from '../../services/contextFactory';
import { ILoadingContext, ILoadingProps } from './interfaces';

export const loadingDefaultValue = {
  isLoading: false,
  hideLoader() {},
  showLoader() {},
};

const { ContextProvider, withConsumer } = contextFactory<
  ILoadingContext,
  ILoadingProps
>({
  defaultValues: loadingDefaultValue,
  displayName: 'LoadingProvider',
  propsToContext(props): ILoadingContext {
    return {
      ...props,
    };
  },
});

export const LoadingProvider = ContextProvider;

export const withLoadingAPI = withConsumer;
