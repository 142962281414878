import React from 'react';
import { ResolveStepContext, IResolveStepContext } from './ResolveStepContext';

export const ResolveStepDataProvider: React.FC<IResolveStepContext> = (
  props,
) => {
  return (
    <ResolveStepContext.Provider
      value={{
        isResolveStepRequestInProgress: props.isResolveStepRequestInProgress,
        resolveStep: props.resolveStep,
        resolveStepError: props.resolveStepError,
      }}
    >
      {props.children}
    </ResolveStepContext.Provider>
  );
};
