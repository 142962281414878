import React from 'react';

import {
  IMonitoringContext,
  MonitoringContext,
  monitoringDefaultValue,
} from './MonitoringContext';

export const MonitoringProvider: React.FC<IMonitoringContext> = (props) => {
  return (
    <MonitoringContext.Provider
      value={{
        startInteraction:
          props.startInteraction || monitoringDefaultValue.startInteraction,
        endInteraction:
          props.endInteraction || monitoringDefaultValue.endInteraction,
      }}
    >
      {props.children}
    </MonitoringContext.Provider>
  );
};
