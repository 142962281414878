import React from 'react';

import {
  ParticipantSection,
  ParticipantStep,
} from '@wix/ambassador-challenges-v1-participant/types';
import { CompletedIcon } from '../components-shared/CompletedIcon';
import { RunningIcon } from '../components-shared/RunningIcon';
import {
  getFirstAvailableStep,
  isStepResolved,
} from '../components/ChallengesPage/Widget/views/utils';
import LockedIcon from '../assets/icons/locked.svg';

const STEP_INDICATOR: Record<'completed' | 'locked' | 'running', React.FC> = {
  completed: CompletedIcon,
  locked: LockedIcon,
  running: RunningIcon,
};

export const isSectionLocked = (section: ParticipantSection): boolean => {
  return !!section?.transitions?.[0]?.waitingDate;
};

export const getFirstAvailableStepFromSection = (
  sections: ParticipantSection[] = [],
): ParticipantStep => {
  let step: ParticipantStep;

  sections.forEach((section) => {
    if (step || isSectionLocked(section)) {
      return;
    }

    step = getFirstAvailableStep(section.steps);
  });

  return step;
};

const isSectionCompleted = (steps: ParticipantStep[]): boolean => {
  return steps.every((step) => isStepResolved(step));
};

export const getSectionIcon = (section: ParticipantSection): React.FC => {
  if (isSectionCompleted(section.steps)) {
    return STEP_INDICATOR.completed;
  }

  if (section.transitions[0]?.waitingDate) {
    return STEP_INDICATOR.locked;
  }

  return STEP_INDICATOR.running;
};

export function getFirstAvailableSection(sections: ParticipantSection[] = []) {
  return sections.find((section) => !isSectionLocked(section));
}

export function getFirstSection(sections: ParticipantSection[] = []) {
  return getFirstAvailableSection(sections) || sections?.[0];
}
