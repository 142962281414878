import React from 'react';
import { ILocalStorageProps } from './interfaces';
import { LocalStorageContext } from './LocalStorageContext';

export const LocalStorageProvider: React.FC<ILocalStorageProps> = (props) => {
  return (
    <LocalStorageContext.Provider
      value={{
        setItem: props.setItem,
        requestItem: props.requestItem,
        storage: props.storage,
      }}
    >
      {props.children}
    </LocalStorageContext.Provider>
  );
};

LocalStorageProvider.displayName = 'LocalStorage.Provider';
