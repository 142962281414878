import { contextFactory } from '../../services/contextFactory';
import { IPaidPlansContext, IPaidPlansProviderProps } from './interfaces';
import React from 'react';

const { ContextProvider, withConsumer, context } = contextFactory<
  IPaidPlansContext,
  IPaidPlansProviderProps
>({
  defaultValues: {
    userPaidPlans: [],
    eligiblePlans: [],
  },
  displayName: 'InviteLinkProvider',
  propsToContext(props): IPaidPlansContext {
    return {
      ...props,
    };
  },
});

export const useMemberPaidPlans = () => React.useContext(context);

export const PaidPlansProvider = ContextProvider;
export const withPaidPlans = withConsumer;
export const PaidPlansContext = context;
